import React from "react";

import { withRouter } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Tag } from "primereact/tag";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { addLocale } from "primereact/api";
import { RadioButton } from "primereact/radiobutton";
import { strings } from "../../utils/strings";

import { uniqueId } from "lodash";
import filesize from "filesize";

import NavBar from "../../componentes/Navbar";
import Upload from "../../componentes/Upload/Index";
import FileList from "../../componentes/FileList/index";

import PessoaService from "../../app/service/PessoaService";
import LocalStorageService from "../../app/service/localStorageService";
import GrupoPessoasUsuariosService from "../../app/service/GrupoPessoasUsuariosService";
import EstadoService from "../../app/service/EstadoService";
import CidadeService from "../../app/service/CidadeService";
import CepService from "../../app/service/CepService";
import UploadPessoaService from "../../app/service/UploadPessoaService";
import ArquivoPessoaService from "../../app/service/ArquivoPessoaService";
import AtendimentoService from "../../app/service/AtendimentoService";
import { AuthContext } from "../../main/ProvedorAutenticacao";
import { Loading } from "../../componentes/Loading";

import "./pessoa.css";
import { MultiSelect } from "primereact/multiselect";
import EspecialidadesService from "../../app/service/EspecialidadesService";

class ConsultarPessoa extends React.Component {
  constructor(props) {
    super(props);

    this.pessoaService = new PessoaService();
    this.grupoPessoaUsuariosService = new GrupoPessoasUsuariosService();
    this.estadoService = new EstadoService();
    this.cidadeService = new CidadeService();
    this.cepService = new CepService();
    this.uploadPessoaService = new UploadPessoaService();
    this.arquivoPessoaService = new ArquivoPessoaService();
    this.atendimentoService = new AtendimentoService();
    this.especialidadesService = new EspecialidadesService();

    this.onTipoPessoaChange = this.onTipoPessoaChange.bind(this);
    this.onChangePerfil = this.onChangePerfil.bind(this);
    this.onChangeEstadoCivil = this.onChangeEstadoCivil.bind(this);
    this.onChangeTipoEndereco = this.onChangeTipoEndereco.bind(this);
    this.onChangeEstado = this.onChangeEstado.bind(this);
    this.onChangeEstadoCrm = this.onChangeEstadoCrm.bind(this);
    this.onChangeCidade = this.onChangeCidade.bind(this);
    this.onChangeEstadoCivil = this.onChangeEstadoCivil.bind(this);
    this.onChangeTipoPessoa = this.onChangeTipoPessoa.bind(this);
    this.acoesArquivos = this.acoesArquivos.bind(this);
    this.acceptExcluirArquivo = this.acceptExcluirArquivo.bind(this);
    this.acoesProcessos = this.acoesProcessos.bind(this);
    this.onChangeTipoConta = this.onChangeTipoConta.bind(this);
    this.acoesAtendimentos = this.acoesAtendimentos.bind(this);
    this.renderFooterDescricaoArquivo =
      this.renderFooterDescricaoArquivo.bind(this);

    this.state = {
      pessoa: null,
      codigo: "",
      nome: "",
      labelNome: "",
      tipoPessoa: null,
      perfil: null,
      perfis: [],
      dataNascimento: "",
      profissao: "",
      atividadeEconomica: "",
      estadoCivil: null,
      estadosCivil: [],
      cpfCnpj: "",
      rg: "",
      passaporte: "",
      tituloEleitor: "",
      reservista: "",
      pis: "",
      cnh: "",
      ctps: "",
      nomeMae: "",
      nomePai: "",
      naturalidade: "",
      nacionalidade: "",
      email: "",
      telefone1: "",
      telefone2: "",
      endereco: "",
      numero: "",
      tipoEndereco: null,
      tiposEndereco: [],
      bairro: "",
      cep: "",
      estado: null,
      estados: [],
      cidade: null,
      cidades: [],
      contaCorrente: "",
      tipoConta: null,
      agencia: "",
      banco: "",
      pix: "",
      observacao: "",
      status: "",
      classeDiv: "",
      detail: "",
      defineTipoPessoa: {},
      defineTipoPessoaJuridica: {},
      processos: [],
      //Arquivos
      upLoadFiles: [],

      descricao: "",
      arquivos: [],
      modalDescricaoArquivo: false,
      arquivo: null,
      arquivoCodigo: "",
      visibleExcluirArquivo: false,
      //Atendimentos
      atendimentos: [],
      openSpinner: false,
      cdconselho: "",
      estadoCrm: [],
      especialidades: [],
      especialidadesOptions: [],
      oab: "",
      //parceriro's states
      parceiro: null,
      parceiros: [],
    };

    this.conta = [
      { codigo: "C", nome: "Conta corrente" },
      { codigo: "P", nome: "Poupança" },
    ];

    addLocale("es", {
      firstDayOfWeek: 1,
      dayNames: [
        "Domingo",
        "Domingo",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      dayNamesShort: [
        "Domingo",
        "Domingo",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Stembro",
        "Outubro",
        "Novembro",
        "Dezemro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoy",
      clear: "Claro",
    });
  }

  onSelectedEspecialidades = (e) => {
    const { value } = e.target;
    this.setState({ especialidades: value });
  };

  getAllEspecialidades = () => {
    this.especialidadesService.getAllEspecialidades().then((res) => {
      let data = res.data;
      this.setState({ especialidadesOptions: data });
    });
  };

  consultarAtendimentos = (pessoa) => {
    this.atendimentoService
      .consultarAtendimentosPorPessoa(pessoa)
      .then((response) => {
        this.setState({ atendimentos: response.data });
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Pessoas",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  };

  acoesAtendimentos(rowData) {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-comments"
          className="p-button-rounded p-button-text"
          tooltip="Abrir registros de atendimento"
          onClick={() => this.consultarAtendimento(rowData)}
          tooltipOptions={{ position: "top" }}
        />
      </React.Fragment>
    );
  }

  consultarAtendimento(atendimento) {
    this.props.history.push(
      `/historicoAtendimento/${atendimento.codigo}/pessoa/${this.state.pessoa.codigo}`
    );
  }

  onChangeTipoConta(e) {
    this.setState({ tipoConta: e.value });
  }

  onChangeDefineTipoPessoa = () => {
    if (this.state.tipoPessoa === "J") {
      this.setState({
        defineTipoPessoa: {
          display: "none",
        },
      });
      this.setState({
        defineTipoPessoaJuridica: {
          display: "block",
        },
      });
      this.setState({ labelNome: "Empresa" });
    } else {
      this.setState({
        defineTipoPessoa: {
          display: "block",
        },
      });
      this.setState({
        defineTipoPessoaJuridica: {
          display: "none",
        },
      });
      this.setState({ labelNome: "Nome" });
    }
  };

  async onChangeTipoPessoa(e) {
    await this.setState({ tipoPessoa: e.value });
    this.onChangeDefineTipoPessoa();
  }

  async componentDidMount() {
    const params = this.props.match.params;
    if (params.codigo) {
      await this.consultarPessoa(params.codigo);
    }
  }

  listarPerfisPessoa = async () => {
    await this.pessoaService
      .listarPerfilPessoa()
      .then((response) => {
        this.setState({ perfis: response.data });
      })
      .catch((error) => {
        this.toast.show({
          severity: "error",
          summary: "Pessoas",
          detail: "Erro ao listar tipos de perfis de pessoa",
          life: 4000,
        });
      });
  };

  onChangePerfil(e) {
    this.setState({ perfil: e.value });
  }

  listarEstadosCivil = async () => {
    await this.pessoaService
      .listarEstadoCivil()
      .then((response) => {
        this.setState({ estadosCivil: response.data });
      })
      .catch((error) => {
        this.toast.show({
          severity: "error",
          summary: "Pessoas",
          detail: "Erro ao listar tipos de estados civis",
          life: 4000,
        });
      });
  };

  onChangeEstadoCivil(e) {
    this.setState({ estadoCivil: e.value });
  }
  listarTiposEndereco = async () => {
    await this.pessoaService
      .listarTiposEndereco()
      .then((response) => {
        this.setState({ tiposEndereco: response.data });
      })
      .catch((error) => {
        this.toast.show({
          severity: "error",
          summary: "Pessoas",
          detail: "Erro ao listar tipos de endereço",
          life: 4000,
        });
      });
  };

  onChangeTipoEndereco(e) {
    this.setState({ tipoEndereco: e.value });
  }

  onTipoPessoaChange(e) {
    this.setState({ tipoPessoa: e.value });
  }

  async buscarDadosPorCep(cep) {
    try {
      const cepLimpo = cep.replace(/[^\d]/g, "");

      const response = await this.cepService.buscarEndereco(cepLimpo);
      if (response && response.data) {
        const { state } = response.data;

        if (state) {
          await this.selecionarEstadoPorUf(state, response.data.city);
        }
      } else {
        console.error("Dados do CEP não encontrados.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados do CEP", error);
    }
  }

  selecionarEstadoPorUf = async (uf, cidade) => {
    const estadoSelecionado = this.state.estados.find(
      (estado) => estado.estado === uf
    );

    if (estadoSelecionado) {
      this.setState({ estado: estadoSelecionado });
      await this.listarCidades();
      await this.selecionarCidadePorNome(cidade);
    } else {
      console.error(`Estado com UF ${uf} não encontrado.`);
    }
  };

  selecionarCidadePorNome = async (cidade) => {
    const normalizeString = (str) => {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    };

    const cidadeNormalizada = normalizeString(cidade);
    const listCidades = this.state.cidades;
    const cidadeSelecionada = this.state.cidades.find((cidadeObj) => {
      const nomeCidadeNormalizada = normalizeString(cidadeObj.nome);
      return nomeCidadeNormalizada === cidadeNormalizada;
    });

    if (cidadeSelecionada) {
      this.setState({ cidade: cidadeSelecionada });
    } else {
      console.error(`Cidade ${cidade} não encontrada.`);
    }
  };

  listarEstados = async () => {
    this.estadoService
      .listarEstados()
      .then((response) => {
        this.setState({ estados: response.data });
      })
      .catch((error) => {
        this.toast.show({
          severity: "error",
          summary: "Pessoas",
          detail: "Erro ao listar estados",
          life: 4000,
        });
      });

    this.listarCidades();
  };

  async onChangeEstado(e) {
    await this.setState({ estado: e.value });
    this.listarCidades();
  }

  async onChangeEstadoCrm(e) {
    await this.setState({ estadoCrm: e.value });
  }

  listarCidades = async () => {
    if (this.state.estado) {
      await this.cidadeService
        .listarCidades(this.state.estado.codigo)
        .then((response) => {
          this.setState({ cidades: response.data });
        })
        .catch((error) => {
          this.setState(error.response.data);
          this.toast.show({
            severity: "error",
            summary: "Pessoas",
            detail: `Erro ao listar Cidades`,
            life: 4000,
          });
        });
    }
  };

  onChangeCidade(e) {
    this.setState({ cidade: e.value });
  }

  async consultarPessoa(codigo) {
    this.setState({ openSpinner: true });
    await this.pessoaService
      .consultarPessoa(codigo)
      .then((response) => {
        this.setState({ pessoa: response.data });
        this.setState({ codigo: response.data.codigo });
        this.setState({ nome: response.data.nome });
        this.setState({ tipoPessoa: response.data.tipoPessoa });
        if (response.data.tipoPessoa === "F") {
          this.setState({ defineTipoPessoa: { display: "block" } });
          this.setState({ defineTipoPessoaJuridica: { display: "none" } });
          this.setState({ labelNome: "Nome" });
        } else {
          this.setState({ defineTipoPessoa: { display: "none" } });
          this.setState({ defineTipoPessoaJuridica: { display: "block" } });
          this.setState({ labelNome: "Empresa" });
        }
        this.setState({ situacaoPessoa: response.data.situacaoPessoa });
        var dataNasc = new Date(response.data.dataNascimento);
        dataNasc.setDate(dataNasc.getDate() + 1);
        this.setState({ dataNascimento: new Date(Date.parse(dataNasc)) });
        this.setState({ profissao: response.data.profissao });
        this.setState({ atividadeEconomica: response.data.atividadeEconomica });
        this.setState({ estadoCivil: response.data.estadoCivil });
        this.setState({ cpfCnpj: response.data.cpfCnpj });
        this.setState({ rg: response.data.rg });
        this.setState({ passaporte: response.data.passaporte });
        this.setState({ tituloEleitor: response.data.tituloEleitor });
        this.setState({ reservista: response.data.reservista });
        this.setState({ pis: response.data.pis });
        this.setState({ cnh: response.data.cnh });
        this.setState({ ctps: response.data.ctps });
        this.setState({ nomeMae: response.data.nomeMae });
        this.setState({ nomePai: response.data.nomePai });
        this.setState({ naturalidade: response.data.naturalidade });
        this.setState({ nacionalidade: response.data.nacionalidade });
        this.setState({ perfil: response.data.perfil });
        this.setState({ email: response.data.email });
        this.setState({ telefone1: response.data.telefone1 });
        this.setState({ telefone2: response.data.telefone2 });
        this.setState({ endereco: response.data.endereco });
        this.setState({ numero: response.data.numero });
        this.setState({ tipoEndereco: response.data.tipoEndereco });
        this.setState({ bairro: response.data.bairro });
        this.setState({ cep: response.data.cep });
        this.setState({ estado: response.data.cidade.estado });
        this.setState({ cidade: response.data.cidade });
        this.setState({ contaCorrente: response.data.contaCorrente });
        this.setState({ agencia: response.data.agencia });
        this.setState({ banco: response.data.banco });
        this.setState({ tipoConta: response.data.tipoConta });
        if (response.data.tipoConta === "C") {
          this.setState({ tipoConta: { codigo: "C", nome: "Conta corrente" } });
        } else if (response.data.tipoConta === "P") {
          this.setState({ tipoConta: { codigo: "P", nome: "Poupança" } });
        }
        this.setState({ pix: response.data.pix });
        this.setState({ observacao: response.data.observacao });
        this.setState({ status: response.data.status });
        this.setState({ especialidades: response.data.especialidade });
        this.setState({ cdconselho: response.data.cdconselho });
        this.setState({ estadoCrm: response.data.estadoCrm });
        this.setState({ oab: response.data.oab });
        this.setState({ parceiro: response.data.parceiro });
        this.listarPerfisPessoa();
        this.listarPerfisPessoa();
        this.listarEstadosCivil();
        this.listarTiposEndereco();
        this.listarEstados();
        this.getAllEspecialidades();
        this.listarParceiros();
        this.listarArquivosPessoa(response.data.codigo);
        this.consultarProcessos(response.data.codigo);
        this.consultarAtendimentos(response.data.codigo);
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Pessoas",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
    this.setState({ openSpinner: false });
  }

  consultarProcessos = async (pessoa) => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.pessoaService
      .consultarProcessos(pessoa, usuarioLogado.codigo)
      .then((response) => {
        this.setState({ processos: response.data });
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Processos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  };

  arquivado(rowData) {
    if (rowData.statusProcessual.descricao === "Arquivado") {
      return (
        <React.Fragment>
          <Tag className="p-mr-2" severity="info" value="Arquivado"></Tag>
        </React.Fragment>
      );
    }
  }

  importante(rowData) {
    return (
      <div className="row gap-1">
        <Button
          icon="pi pi-star"
          className={rowData.importanteParaMim}
          style={{ width: "29px", height: "26px" }}
          disabled="true"
        />
        <Button
          icon="pi pi-heart"
          className={rowData.importanteParaEmpresa}
          style={{ width: "29px", height: "26px" }}
          disabled="false"
        />
      </div>
    );
  }

  acoesProcessos(rowData) {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          tooltip="Editar"
          onClick={() => this.editarProcesso(rowData)}
          tooltipOptions={{ position: "top" }}
        />
      </React.Fragment>
    );
  }

  editarProcesso(processo) {
    //this.props.history.push(`/fichaProcesso/${processo.codigo}`);
    window.open(`/#/fichaProcesso/${processo.codigo}`, "_blank");
  }

  //Upload de Documentos
  handleUpload = async (files) => {
    const upLoadFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      url: file.url,
    }));

    this.setState({
      upLoadFiles: this.state.upLoadFiles.concat(upLoadFiles),
    });

    await upLoadFiles.forEach(this.processar);
  };

  updateFile = (id, data) => {
    this.setState({
      upLoadFiles: this.state.upLoadFiles.map((upLoadFile) => {
        return id === upLoadFile.id ? { ...upLoadFile, ...data } : upLoadFile;
      }),
    });
  };

  processar = async (upLoadFile) => {
    const usuarioLogado = this.context.usuarioAutenticado;
    const data = new FormData();
    data.append("file", upLoadFile.file);
    data.append("pessoa", upLoadFile.file, this.state.codigo); //Envia código da pessoa para criar diretório
    data.append("usuario", upLoadFile.file, usuarioLogado.codigo);
    await this.arquivoPessoaService
      .incluirArquivo(data)
      .then((response) => {
        this.listarArquivosPessoa(this.state.codigo);
        this.handleDelete(upLoadFile.id);
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Arquivos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  };

  listarArquivosPessoa = async (codigo) => {
    await this.arquivoPessoaService
      .listarArquivos(codigo)
      .then((response) => {
        this.setState({ arquivos: response.data });
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Arquivos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  };

  handleDelete = async (id) => {
    this.setState({
      upLoadFiles: this.state.upLoadFiles.filter((file) => file.id !== id),
    });
  };

  async consultarArquivo(codigo) {
    await this.arquivoPessoaService
      .consultarArquivo(codigo)
      .then((response) => {
        this.setState({ arquivo: response.data });
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Arquivos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  }

  async consultarArquivoDescricao(rowData) {
    await this.arquivoPessoaService
      .consultarArquivo(rowData.codigo)
      .then((response) => {
        this.setState({ arquivo: response.data });
        this.setState({ descricao: response.data.descricao });
        this.abrirModalDescricaoArquivo("modalDescricaoArquivo");
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Arquivos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  }

  renderFooterDescricaoArquivo(name) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary bg-gradient-secondary"
          onClick={() => this.fecharModalCadastro(name)}
        >
          <i className="fas fa-times"></i> Cancelar
        </button>
        <button
          type="button"
          className="btn btn-primary bg-gradient-success"
          onClick={() => this.adicionarDescricao()}
        >
          <i className="fas fa-check"></i> Alterar
        </button>
      </div>
    );
  }

  async adicionarDescricao() {
    this.setState({ openSpinner: true });
    await this.arquivoPessoaService
      .adicionarDescricao({
        codigo: this.state.arquivo.codigo,
        descricao: this.state.descricao,
      })
      .then((response) => {
        this.toast.show({
          severity: "success",
          summary: "Arquivos",
          detail: "Descrição adicionada com sucesso",
          life: 4000,
        });
        this.listarArquivosPessoa(this.state.pessoa.codigo);
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Arquivos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
    this.setState({ openSpinner: false });
    this.fecharModalCadastro("modalDescricaoArquivo");
  }

  fecharModalCadastro(name) {
    this.setState({
      [`${name}`]: false,
    });
  }

  abrirModalDescricaoArquivo(name, position) {
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      };
    }
    this.setState(state);
  }

  excluirArquivo = async () => {
    this.setState({ openSpinner: true });
    await this.arquivoPessoaService
      .excluirArquivo(this.state.arquivoCodigo)
      .then((response) => {
        this.toast.show({
          severity: "success",
          summary: "Arquivos",
          detail: "Arquivo excluído com sucesso",
          life: 4000,
        });
        this.listarArquivosPessoa(this.state.pessoa.codigo);
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Arquivos",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
    this.setState({ openSpinner: false });
  };

  confirmaExclusaoArquivo(arquivo) {
    this.setState({ arquivoCodigo: arquivo.codigo });
    confirmDialog({
      message: "Deseja realmente excluir este arquivo?",
      header: "Exclusão de arquivos",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: this.acceptExcluirArquivo,
      reject: this.reject,
    });
  }

  acceptExcluirArquivo() {
    this.excluirArquivo();
  }

  async download(rowData) {
    await this.consultarArquivo(rowData.codigo);
    await this.arquivoPessoaService
      .download(rowData.codigo)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.state.arquivo.arquivo); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  acoesArquivos(rowData) {
    return (
      <div className="row gap-1">
        <Button
          icon="pi pi-comment"
          className="p-button-rounded p-button-text"
          tooltip="Adicionar descrição ao arquivo"
          tooltipOptions={{ position: "top" }}
          onClick={() => this.consultarArquivoDescricao(rowData)}
          style={{ width: "20px", height: "10px" }}
        />
        <Button
          icon="pi pi-cloud-download"
          className="p-button-rounded p-button-text"
          tooltip="Baixar arquivo"
          onClick={() => this.download(rowData)}
          tooltipOptions={{ position: "top" }}
          style={{ width: "20px", height: "10px" }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          tooltip="Excluir arquivo"
          onClick={() => this.confirmaExclusaoArquivo(rowData)}
          tooltipOptions={{ position: "top" }}
          style={{ width: "20px", height: "10px" }}
        />
      </div>
    );
  }

  pad2 = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  alterarPessoa = () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    //Data de nascmento
    var dataFormatadaDataNascimento = "";
    if (this.state.dataNascimento !== "") {
      var date = this.state.dataNascimento;
      var month = this.pad2(date.getMonth() + 1); //months (0-11)
      var day = this.pad2(date.getDate()); //day (1-31)
      var year = date.getFullYear();
      dataFormatadaDataNascimento = year + "-" + month + "-" + day;
    }
    this.setState({ openSpinner: true });
    const pessoaAlterada = {
      codigo: this.state.pessoa.codigo,
      tipoPessoa: this.state.tipoPessoa,
      nome: this.state.nome,
      perfil: this.state.perfil,
      dataNascimento: dataFormatadaDataNascimento,
      profissao: this.state.profissao,
      atividadeEconomica: this.state.atividadeEconomica,
      estadoCivil: this.state.estadoCivil,
      cpfCnpj: this.state.cpfCnpj,
      rg: this.state.rg,
      passaporte: this.state.passaporte,
      tituloEleitor: this.state.tituloEleitor,
      reservista: this.state.reservista,
      pis: this.state.pis,
      cnh: this.state.cnh,
      ctps: this.state.ctps,
      nomeMae: this.state.nomeMae,
      nomePai: this.state.nomePai,
      naturalidade: this.state.naturalidade,
      nacionalidade: this.state.nacionalidade,
      email: this.state.email,
      telefone1: this.state.telefone1,
      telefone2: this.state.telefone2,
      endereco: this.state.endereco,
      numero: this.state.numero,
      tipoEndereco: this.state.tipoEndereco,
      bairro: this.state.bairro,
      cep: this.state.cep,
      cidade: this.state.cidade,
      tipoConta: this.state.tipoConta.codigo,
      contaCorrente: this.state.contaCorrente,
      agencia: this.state.agencia,
      banco: this.state.banco,
      pix: this.state.pix,
      status: this.state.status,
      observacao: this.state.observacao,
      empresa: usuarioLogado.empresa,
      usuario: usuarioLogado.codigo,
      cdconselho: this.state.cdconselho,
      estadoCrm: this.state.estadoCrm,
      oab: this.state.oab,
      especialidade: this.state.especialidades,
      parceiro: this.state.parceiro, //agora captador se chama parceriro
    };
    this.pessoaService
      .alterarPessoa(pessoaAlterada)
      .then((response) => {
        this.toast.show({
          severity: "success",
          summary: "Edição de pessoas",
          detail: "Pessoa alterada com sucesso!",
          life: 4000,
        });
        console.log(response);
        setTimeout(() => {
          this.props.history.push("/pessoas");
        }, 1500);
      })
      .catch((error) => {
        console.log(pessoaAlterada);

        let detail = error.response.data.detail;
        this.toast.show({
          severity: "error",
          summary: "Edição de pessoas",
          detail: `${detail}`,
          life: 4000,
        });
      });
    this.setState({ openSpinner: false });
  };

  voltar = () => {
    const consultaFiltro = LocalStorageService.obterConsulta(
      "_consulta_pessoa_filtro"
    );
    const consultaFiltroJson = JSON.parse(consultaFiltro);

    if (consultaFiltroJson != null) {
      let params = `/pessoas?empresa=${consultaFiltroJson.empresa}&usuario=${consultaFiltroJson.usuario}`;

      if (consultaFiltroJson.nome != null) {
        params = `${params}&nome=${consultaFiltroJson.nome}`;
      }

      if (consultaFiltroJson.cpfCnpj != null) {
        params = `${params}&cpfCnpj=${consultaFiltroJson.cpfCnpj}`;
      }

      if (consultaFiltroJson.cidade != null) {
        params = `${params}&cidade=${consultaFiltroJson.cidade}`;
      }

      if (consultaFiltroJson.telefone != null) {
        params = `${params}&telefone=${consultaFiltroJson.telefone}`;
      }

      if (consultaFiltroJson.porLetra != null) {
        params = `${params}&letra=${consultaFiltroJson.letra}&porLetra=${consultaFiltroJson.porLetra}`;
      }

      if (consultaFiltroJson.grupo != null) {
        params = `${params}&grupo=${consultaFiltroJson.grupo}`;
      }

      this.props.history.push(params);
    } else {
      this.props.history.push("/pessoas");
    }
  };
  // parceiro's func
  onChangeParceiro = (e) => {
    this.setState({ parceiro: e.value });
  };

  listarParceiros = () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    this.pessoaService
      .listarParceiros("CP", usuarioLogado.empresa.codigo)
      .then((response) => {
        this.setState({ parceiros: response.data });
      })
      .catch((error) => {
        this.setState(error.response.data);
        this.toast.show({
          severity: "error",
          summary: "Cadastro de pessoas",
          detail: `${this.state.detail}`,
          life: 4000,
        });
      });
  };

  render() {
    const { upLoadFiles } = this.state;
    return (
      <>
        <NavBar />
        <Loading open={this.state.openSpinner} />
        <Toast ref={(el) => (this.toast = el)} />
        <div className="content-wrapper">
          <div className="container-fluid">
            <section className="content-header">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-user-tie"></i>
                    <span> Consulta de pessoas</span>
                  </h3>
                </div>
                <div className="card-body">
                  {/*<div className="row">*/}
                  <div className="flex-row w-full gap-1">
                    <div className="flex-row">
                      <label>
                        Tipo de pessoa
                        <span className="obrigatorioAsterisco"> *</span>
                      </label>
                      <div className="flex-row gap-0">
                        <RadioButton
                          inputId="tipoPessoaFisica"
                          name="tipoPessoa"
                          value="F"
                          onChange={this.onChangeTipoPessoa}
                          checked={this.state.tipoPessoa === "F"}
                        />
                        <label htmlFor="tipoPessoaFisica">Física</label>
                        <RadioButton
                          inputId="tipoPessoaJuridica"
                          name="tipoPessoa"
                          value="J"
                          onChange={this.onChangeTipoPessoa}
                          checked={this.state.tipoPessoa === "J"}
                        />
                        <label htmlFor="tipoPessoaJuridica">Jurídica</label>
                      </div>
                    </div>
                    {/*</div>*/}
                    {/*<div className="col-sm-5">*/}
                    <div className="flex-row">
                      <label>
                        Situação pessoa
                        <span className="obrigatorioAsterisco"> *</span>
                      </label>
                      <div className="flex-row gap-0">
                        <RadioButton
                          inputId="statusSituacao"
                          name="status"
                          value={0}
                          onChange={(e) =>
                            this.setState({ status: e.target.value })
                          }
                          checked={this.state.status === 0}
                        />
                        <label htmlFor="situacaoAtiva">Ativo</label>
                        <RadioButton
                          inputId="statusSituacao"
                          name="status"
                          value={1}
                          onChange={(e) =>
                            this.setState({ status: e.target.value })
                          }
                          checked={this.state.status === 1}
                        />
                        <label htmlFor="situacaoInativo">Inativo</label>
                      </div>
                    </div>
                  </div>
                  {/*</div>*/}
                  <TabView className="tabview-custom">
                    <TabPanel header="Informações pessoais">
                      <div className="row">
                        <div className="col-sm-7">
                          <div className="form-group">
                            <label>
                              {this.state.labelNome}
                              <span className="obrigatorioAsterisco"> *</span>
                            </label>
                            <InputText
                              id="nome"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>
                              Perfil pessoa
                              <span className="obrigatorioAsterisco"> *</span>
                            </label>
                            <Dropdown
                              value={this.state.perfil}
                              options={this.state.perfis}
                              style={{ width: "100%", height: "37px" }}
                              optionLabel="nome"
                              onChange={this.onChangePerfil}
                              id="perfilPessoa"
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>Telefone 1</label>
                            <InputMask
                              id="telefone1"
                              mask="(99)-99999-9999"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.telefone1}
                              onChange={(e) =>
                                this.setState({ telefone1: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>Telefone 2</label>
                            <InputMask
                              id="telefone1"
                              mask="(99)-99999-9999"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.telefone2}
                              onChange={(e) =>
                                this.setState({ telefone2: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-7">
                          <div className="form-group">
                            <label>Email</label>
                            <InputText
                              id="email"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Tp. end.</label>
                            <Dropdown
                              value={this.state.tipoEndereco}
                              options={this.state.tiposEndereco}
                              style={{ width: "100%", height: "37px" }}
                              optionLabel="nome"
                              onChange={this.onChangeTipoEndereco}
                              id="tipoEndereco"
                              className="p-inputtext-sm p-d-block p-mb-0"
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>Cep</label>
                            <InputText
                              id="cep"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.cep}
                              onChange={async (e) => {
                                let cep = e.target.value;
                                const cepLimpo = cep.replace(/[^\d]/g, "");

                                this.setState({ cep });

                                // Verificar se o cepLimpo tem 8 dígitos para buscar os dados
                                if (cepLimpo.length === 8) {
                                  await this.buscarDadosPorCep(cepLimpo);
                                }
                              }}
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>Número</label>
                            <InputText
                              id="numero"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>Endereço</label>
                            <InputText
                              id="endereco"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.endereco}
                              onChange={(e) =>
                                this.setState({ endereco: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-0"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Bairro</label>
                            <InputText
                              id="bairro"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {this.state.estado === null ? (
                          <div className="col-sm-7">
                            <div className="form-group">
                              <label>
                                Estado
                                <span className="obrigatorioAsterisco"> *</span>
                              </label>
                              <Dropdown
                                value={this.state.estado}
                                options={this.state.estados}
                                optionLabel="estado"
                                filter
                                showClear
                                filterBy="estado"
                                style={{ width: "100%" }}
                                onChange={this.onChangeEstado}
                                id="estado"
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label>
                                  Estado
                                  <span className="obrigatorioAsterisco">
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <Dropdown
                                  value={this.state.estado}
                                  options={this.state.estados}
                                  optionLabel="estado"
                                  filter
                                  showClear
                                  filterBy="estado"
                                  style={{ width: "100%" }}
                                  onChange={this.onChangeEstado}
                                  id="estado"
                                  className="p-inputtext-sm p-d-block p-mb-1"
                                />
                              </div>
                            </div>
                            <div className="col-sm-5">
                              <div className="form-group">
                                <label>
                                  Cidade
                                  <span className="obrigatorioAsterisco">
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <Dropdown
                                  value={this.state.cidade}
                                  options={this.state.cidades}
                                  optionLabel="nome"
                                  filter
                                  showClear
                                  filterBy="nome"
                                  style={{ width: "100%" }}
                                  onChange={this.onChangeCidade}
                                  id="cidade"
                                  className="p-inputtext-sm p-d-block p-mb-1"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel header="Informações Complementares">
                      <span style={this.state.defineTipoPessoa}>
                        <div className="row">
                          <div className="col-sm-2">
                            <div className="form-group">
                              <label>Data nasc.</label>
                              <Calendar
                                id="mask"
                                value={this.state.dataNascimento}
                                onChange={(e) =>
                                  this.setState({ dataNascimento: e.value })
                                }
                                locale="es"
                                style={{ width: "100%", height: "32px" }}
                                dateFormat="dd/mm/yy"
                                mask="99/99/9999"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label>Profissão</label>
                              <InputText
                                id="profissao"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.profissao}
                                onChange={(e) =>
                                  this.setState({ profissao: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label>Atividade econômica</label>
                              <InputText
                                id="atividadeEconomica"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.atividadeEconomica}
                                onChange={(e) =>
                                  this.setState({
                                    atividadeEconomica: e.target.value,
                                  })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-2">
                            <div className="form-group">
                              <label>Estado civil</label>
                              <Dropdown
                                value={this.state.estadoCivil}
                                options={this.state.estadosCivil}
                                style={{ width: "100%", height: "37px" }}
                                optionLabel="nome"
                                onChange={this.onChangeCidade}
                                id="cidade"
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label>Naturalidade</label>
                              <InputText
                                id="naturalidades"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.naturalidade}
                                onChange={(e) =>
                                  this.setState({
                                    naturalidade: e.target.value,
                                  })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label>Nacionalidade</label>
                              <InputText
                                id="naturalidades"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.nacionalidade}
                                onChange={(e) =>
                                  this.setState({
                                    nacionalidade: e.target.value,
                                  })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <label>Nome da mãe</label>
                              <InputText
                                id="nomeMae"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.nomeMae}
                                onChange={(e) =>
                                  this.setState({ nomeMae: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <label>Nome do pai</label>
                              <InputText
                                id="nomePai"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.nomePai}
                                onChange={(e) =>
                                  this.setState({ nomePai: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </span>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group">
                            <label>Parceiro</label>
                            <Dropdown
                              value={this.state.parceiro}
                              options={this.state.parceiros}
                              onChange={this.onChangeParceiro}
                              optionLabel="nome"
                              filter
                              showClear
                              filterBy="nome"
                              style={{ width: "100%", height: "37px" }}
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>Conta bancária</label>
                            <Dropdown
                              value={this.state.tipoConta}
                              options={this.conta}
                              style={{ width: "100%", height: "37px" }}
                              optionLabel="nome"
                              onChange={this.onChangeTipoConta}
                              id="cidade"
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Número da conta</label>
                            <InputText
                              id="conta"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.contaCorrente}
                              onChange={(e) =>
                                this.setState({ contaCorrente: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Agência</label>
                            <InputText
                              id="agencia"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.agencia}
                              onChange={(e) =>
                                this.setState({ agencia: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Banco</label>
                            <InputText
                              id="agencia"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.banco}
                              onChange={(e) =>
                                this.setState({ banco: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label>Chave pix</label>
                            <InputText
                              id="pix"
                              type="text"
                              style={{ width: "100%", height: "32px" }}
                              value={this.state.pix}
                              onChange={(e) =>
                                this.setState({ pix: e.target.value })
                              }
                              className="p-inputtext-sm p-d-block p-mb-1"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Documentação">
                      <span style={this.state.defineTipoPessoa}>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>CPF</label>
                              <InputMask
                                id="cpf"
                                type="text"
                                mask="999.999.999-99"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.cpfCnpj}
                                onChange={(e) =>
                                  this.setState({ cpfCnpj: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>RG</label>
                              <InputText
                                id="RG"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.rg}
                                onChange={(e) =>
                                  this.setState({ rg: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </span>
                      <span style={this.state.defineTipoPessoaJuridica}>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>CNPJ</label>
                              <InputMask
                                id="cnpj"
                                type="text"
                                mask="99.999.999/9999-99"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.cpfCnpj}
                                onChange={(e) =>
                                  this.setState({ cpfCnpj: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </span>
                      <span style={this.state.defineTipoPessoa}>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>CNH</label>
                              <InputText
                                id="cnh"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.cnh}
                                onChange={(e) =>
                                  this.setState({ cnh: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>CTPS</label>
                              <InputText
                                id="cnh"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.ctps}
                                onChange={(e) =>
                                  this.setState({ ctps: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </span>
                      <span style={this.state.defineTipoPessoa}>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>PIS</label>
                              <InputText
                                id="cnh"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.pis}
                                onChange={(e) =>
                                  this.setState({ pis: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Passaporte</label>
                              <InputText
                                id="cnh"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.passaporte}
                                onChange={(e) =>
                                  this.setState({ passaporte: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </span>
                      <span style={this.state.defineTipoPessoa}>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Título de eleitor</label>
                              <InputText
                                id="cnh"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.tituloEleitor}
                                onChange={(e) =>
                                  this.setState({
                                    tituloEleitor: e.target.value,
                                  })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Certidão de reservista</label>
                              <InputText
                                id="cnh"
                                type="text"
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.reservista}
                                onChange={(e) =>
                                  this.setState({ reservista: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Número Conselho</label>
                              <InputText
                                id="crmmedicodfdjghfjgkwt"
                                type="text"
                                maxLength={20}
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.cdconselho}
                                onChange={(e) =>
                                  this.setState({ cdconselho: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Especialidades</label>
                              <MultiSelect
                                inputId="multiselect"
                                value={this.state.especialidades}
                                options={this.state.especialidadesOptions}
                                style={{
                                  width: "100%",
                                  height: "37px",
                                  padding_top: "1px",
                                }}
                                onChange={this.onSelectedEspecialidades}
                                filter
                                showClear
                                filterBy="nome"
                                optionLabel="nome"
                              />
                              {/*<InputText id="espci"*/}
                              {/*           type="text"*/}
                              {/*           maxLength={50}*/}
                              {/*           style={{width: '100%', height: '32px'}}*/}
                              {/*           value={this.state.especialidade}*/}
                              {/*           onChange={e => this.setState({especialidade: e.target.value})}*/}
                              {/*           className="p-inputtext-sm p-d-block p-mb-1"/>*/}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Estado do Conselho</label>
                              <Dropdown
                                value={this.state.estadoCrm}
                                options={this.state.estados}
                                style={{ width: "100%", height: "37px" }}
                                optionLabel="estado"
                                onChange={this.onChangeEstadoCrm}
                                id="estadoCrm"
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>OAB</label>
                              <InputText
                                id="oabkjhgiytuy7945ghdfku"
                                type="text"
                                maxLength={20}
                                style={{ width: "100%", height: "32px" }}
                                value={this.state.oab}
                                onChange={(e) =>
                                  this.setState({ oab: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </span>
                    </TabPanel>
                    <TabPanel header="Arquivos">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="container">
                              <Upload onUpload={this.handleUpload} />
                              {!!upLoadFiles && (
                                <FileList
                                  files={upLoadFiles}
                                  onDelete={this.handleDelete}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <DataTable
                        value={this.state.arquivos}
                        paginator
                        responsiveLayout="scroll"
                        size="small"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                        rows={10}
                        emptyMessage="Nenhum arquivo"
                      >
                        <Column field="arquivo" header="Arquivo" />
                        <Column field="descricao" header="Descrição"></Column>
                        <Column field="usuario.nome" header="Usuário"></Column>
                        <Column
                          body={this.acoesArquivos}
                          exportable={false}
                          style={{ minWidth: "8rem" }}
                          header="Ações"
                        />
                      </DataTable>
                      {/**Adicionar descrição ao arquivo */}
                      <Dialog
                        header="Descrição do arquivo"
                        visible={this.state.modalDescricaoArquivo}
                        style={{ width: "40vw" }}
                        footer={this.renderFooterDescricaoArquivo(
                          "modalDescricaoArquivo"
                        )}
                        onHide={() =>
                          this.fecharModalCadastro("modalDescricaoArquivo")
                        }
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="descricaoArquivo">
                                Descrição
                              </label>
                              <InputText
                                id="descricaoArquivo"
                                type="text"
                                style={{ width: "100%", height: "37px" }}
                                value={this.state.descricao}
                                onChange={(e) =>
                                  this.setState({ descricao: e.target.value })
                                }
                                className="p-inputtext-sm p-d-block p-mb-1"
                              />
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </TabPanel>
                    <TabPanel header="Processos">
                      <DataTable
                        value={this.state.processos}
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                        rows={10}
                        emptyMessage=" "
                        selectionMode="multiple"
                      >
                        <Column field="pasta" header="Pasta"></Column>
                        <Column
                          field="nrProcesso"
                          header="Processo original"
                        ></Column>
                        <Column field="nrCnj" header="Numeração única"></Column>
                        <Column
                          field="autor"
                          header="Autor x Réu"
                          style={{ width: "300px" }}
                        ></Column>
                        <Column
                          field="grupoTrabalho.nome"
                          header="Grupo trab."
                        ></Column>
                        <Column
                          field="dataUltimaMovimentacao"
                          header="Últ. mov."
                        ></Column>
                        <Column
                          body={this.arquivado}
                          exportable={false}
                          style={{ minWidth: "8rem" }}
                        />
                        <Column body={this.importante} exportable={false} />
                        <Column
                          body={this.acoesProcessos}
                          exportable={false}
                          style={{ minWidth: "8rem" }}
                          header="Ações"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                    <TabPanel header="Atendimentos">
                      <DataTable
                        value={this.state.atendimentos}
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                        rows={10}
                        emptyMessage=" "
                        selectionMode="multiple"
                      >
                        <Column field="pessoa.nome" header="Pessoa"></Column>
                        <Column
                          field="grupo.nome"
                          header="Grupo de assuntos"
                        ></Column>
                        <Column field="assunto" header="Assunto"></Column>
                        <Column
                          field="dtAtendimento"
                          header="Data atendimento"
                        ></Column>
                        <Column
                          body={this.acoesAtendimentos}
                          exportable={false}
                          style={{ minWidth: "8rem" }}
                          header="Ações"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                  </TabView>
                  <div className="row gap-1" style={{ paddingLeft: "12px" }}>
                    <Button
                      label="Alterar"
                      className={strings.buttonPrimarySm}
                      onClick={this.alterarPessoa}
                    />
                    <Button
                      label="Voltar"
                      className={strings.buttonSecondarySm}
                      onClick={this.voltar}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
ConsultarPessoa.contextType = AuthContext;
export default withRouter(ConsultarPessoa);
