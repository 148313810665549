class LocalStorageService {
  
  static adicionarItem(chave, valor){
    localStorage.setItem(chave, JSON.stringify(valor));
  }

  static obterItem(chave){
      const item = localStorage.getItem(chave);
      return JSON.parse(item)
  }

  static removerItem(chave){
      localStorage.removeItem(chave)
  }

  static adicionarConsulta(chave, valor) {
    localStorage.setItem(chave,JSON.stringify(valor));
  }

  static obterConsulta(chave) {
    return localStorage.getItem(chave);
  }

  static limparConsultaProcesso() {
    localStorage.removeItem('_consulta_processo_indice');
    localStorage.removeItem('_consulta_processo_filtro');
  }

  static limparConsultaPessoas() {
    localStorage.removeItem('_consulta_pessoa_filtro');
  }
}

export default LocalStorageService