import {useEffect, useState} from "react";
import ConfirmeEmailService from "../../app/service/ConfirmeEmailService";
import {withRouter} from "react-router-dom";


export const ConfirmarEmail = (props) => {
    const [message, setMessage] = useState("Solicitando...")
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false)


    const service = new ConfirmeEmailService()

    function getConfirmerEmail() {
        let params = props.location.search
        if (params.split("=")[1] === undefined) {
            props.history.push("/login")
        }
        let token = params.split("=")[1]
        service.confirmarEmail(token).then(res => {
            setMessage(res.data)
            setIsEmailConfirmed(true)
            setTimeout(() => {
                props.history.push("/login")
            }, 13000)
        }).catch(() => {
            setMessage("Houve um problema ao confirmar seu e-mail. Por favor, tente novamente.")
            setTimeout(() => {
                props.history.push("/login")
            }, 13000)

        })
    }

    useEffect(getConfirmerEmail, [])
    return <>
        <div style={{textAlign: 'center', marginTop: '50px'}}>
            {isEmailConfirmed ? (
                <div>
                    <h1>Sucesso!</h1>
                    <p> {message}</p>
                </div>
            ) : (
                <div>
                    <p> {message}</p>
                </div>
            )}
        </div>

    </>

}
export default withRouter(ConfirmarEmail)
