import React, {useContext} from "react";
import {AuthContext} from "./ProvedorAutenticacao";
import PlanosEmpresa from "../pages/planos/PlanosEmpresa";
import {Redirect, Route} from "react-router-dom";
import {EmpresaConsts} from "../Consts/EmpresaConsts";
import {ADMIN} from "../Consts/PerfilConsts";

export const RoutePainelEmpresas =  ({component: Component, perfil, isUsuarioAutenticado, empresa, ...props}) => {
        const {expirou} = useContext(AuthContext);
        if (expirou) {
            return (
                //redirecionar para pagina de planos
                <PlanosEmpresa/>
            )
        }
        return (
            <Route exact {...props} render={(componentProps) => {
                if (isUsuarioAutenticado && perfil === ADMIN && empresa === EmpresaConsts.ETRIUM) {
                    return <Component {...componentProps} />;
                } else {
                    return <Redirect to={{pathname: '/home', state: {from: componentProps.location}}}/>;
                }
            }}/>
        );
}