import React from 'react'

import { withRouter } from 'react-router-dom'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonSm } from '../../componentes/ButtonSm'

import NavBar from '../../componentes/Navbar'

import PessoaService from '../../app/service/PessoaService'
import LocalStorageService from '../../app/service/localStorageService'
import GrupoPessoasUsuariosService from '../../app/service/GrupoPessoasUsuariosService'
import EstadoService from '../../app/service/EstadoService'
import CidadeService from '../../app/service/CidadeService'
import { AuthContext } from '../../main/ProvedorAutenticacao'

import './pessoa.css'
import { strings } from '../../utils/strings';
import {Loading} from "../../componentes/Loading";


class Pessoas extends React.Component {
  constructor(props) {
    super(props);

    this.pessoaService = new PessoaService();
    this.grupoPessoasUsuariosService = new GrupoPessoasUsuariosService();
    this.estadoService = new EstadoService();
    this.cidadeService = new CidadeService();

    this.onChangeEstado = this.onChangeEstado.bind(this);
    this.onChangeCidade = this.onChangeCidade.bind(this);
    this.onChangeGrupo = this.onChangeGrupo.bind(this);
    this.acoes = this.acoes.bind(this);
    this.accept = this.accept.bind(this);

    this.state = {
      clientes: [],
      nome: null,
      cpfCnpj: null,
      cidade: null,
      telefone: null,
      empresa: null,
      porLetra: null,
      letra: null,
      grupo: null,
      usuario: null,
      detail: '',
      grupos: [],
      estados: [],
      estado: null,
      cidades: [],
      consultaNome: null,
      visible: false,
      letraCheck: '',
      openSpinner: false
    }

    this.alfabeto = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  }

  carregarCombos = () => {
    this.listarGruposUsuario();
    this.listarEstados();
  }

  listarGruposUsuario = async () => {
    const usuarioLogado = this.context.usuarioAutenticado

    await this.grupoPessoasUsuariosService.listarGruposConsulta(usuarioLogado.codigo)
        .then(response => {
          this.setState({ grupos: response.data });
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Grupo de pessoas', detail: `${this.state.detail}`, life: 4000 });
        })
  }

  listarEstados = async () => {
    await this.estadoService.listarEstados()
        .then(response => {
          this.setState({ estados: response.data });
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Estados', detail: `${this.state.detail}`, life: 4000 });
        })
  }

  listarCidades = async () => {
    await this.cidadeService.listarCidades(this.state.estado.codigo)
        .then(response => {
          this.setState({ cidades: response.data });
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'cidades', detail: `${this.state.detail}`, life: 4000 });
        })
  }
  async onChangeEstado(e) {
    await this.setState({ estado: e.value });
    this.listarCidades();
  }

  onChangeCidade(e) {
    this.setState({ cidade: e.value });
  }

  onChangeGrupo(e) {
    this.setState({ grupo: e.value });
  }

  consultarPessoas = async () => {
    this.setState({ openSpinner: true });
    const usuarioLogado = this.context.usuarioAutenticado;
    const filtro = {
      nome: this.state.nome,
      cpfCnpj: this.state.cpfCnpj,
      estado: this.state.estado? this.state.estado.codigo : null,
      cidade: this.state.cidade? this.state.cidade.codigo : null,
      telefone: this.state.telefone,
      empresa: usuarioLogado.empresa.codigo,
      usuario: usuarioLogado.codigo,
      porLetra: this.state.porLetra,
      letra: this.state.letra,
      grupo: this.state.grupo
    }
    await this.pessoaService.consultarPessoas(filtro)
        .then(response => {
          this.setState({ clientes: response.data });
          LocalStorageService.adicionarConsulta('_consulta_pessoa_filtro', filtro);
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Pessoas', detail: `${this.state.detail}`, life: 4000 });
        });

    this.setState({ openSpinner: false });
  }

  componentDidMount() {
    const consultaFiltro = LocalStorageService.obterConsulta('_consulta_pessoa_filtro');
    const consultaFiltroJson = JSON.parse(consultaFiltro);
    if (consultaFiltroJson !== null) {
      this.pessoaService.consultarPessoas(consultaFiltroJson)
          .then(response => {
            this.setState({ clientes: response.data });
          }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({ severity: 'error', summary: 'Pessoas', detail: `${this.state.detail}`, life: 4000 });
      })
    }
  }

  adicionarPessoa = () => {
    this.props.history.push('cadastrarPessoa');
  }

  limparCamposConsulta = () => {
    this.setState({ cpfCnpj: null });
    this.setState({ cidade: null });
    this.setState({ telefone: null });
    this.setState({ porLetra: null });
    this.setState({ letra: null });
    this.setState({ grupo: null });
    this.setState({ nome: null });
    this.setState({ letraCheck: '' });
  }

  consultaRapida = async () => {
    const usuarioLogado = this.context.usuarioAutenticado;
    await this.setState({ cpfCnpj: null });
    this.setState({ cidade: null });
    this.setState({ telefone: null });
    this.setState({ porLetra: null });
    this.setState({ letra: null });
    this.setState({ grupo: null });
    this.setState({ nome: null });
    this.setState({ letraCheck: null });


    const filtro = {
      nome: this.state.consultaNome,
      cpfCnpj: this.state.cpfCnpj,
      cidade: this.state.cidade,
      telefone: this.state.telefone,
      empresa: usuarioLogado.empresa.codigo,
      usuario: usuarioLogado.codigo,
      porLetra: this.state.porLetra,
      letra: this.state.letra,
      grupo: this.state.grupo
    }
    this.setState({ openSpinner: true });
    await this.pessoaService.consultarPessoas(filtro)
        .then(response => {
          this.setState({ clientes: response.data });
          LocalStorageService.adicionarConsulta('_consulta_pessoa_filtro', filtro);
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Pessoas', detail: `${this.state.detail}`, life: 4000 });
        });

    this.setState({ openSpinner: false });
  }

  onSelectLetra = async (letra) => {
    await this.setState({ letraCheck: letra });
    this.setState({ porLetra: 'S' });
    this.setState({ letra: letra });
    this.consultarPessoas();
  }

  acoes(rowData) {
    return (
        <div className="row gap-1">
          {/*<Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-secondary"*/}
          {/*        tooltip="Editar"*/}
          {/*        onClick={() => this.editarPessoa(rowData)}*/}
          {/*        tooltipOptions={{ position: 'top' }} />*/}
          <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger"
                  onClick={() => this.confirmaExclusao(rowData)}
                  tooltip="Excluir pessoa"
                  tooltipOptions={{ position: 'top' }} />
        </div>
    );
  }

  accept() {
    this.excluirPessoa();
  }

  confirmaExclusao(pessoa) {
    this.setState({ codigo: pessoa.codigo });
    confirmDialog({
      message: 'Deseja realmente excluir a pessoa selecionada ?',
      header: 'Exclusão de pessoa',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: this.accept,
      reject: this.reject
    });
  }

  excluirPessoa = async () => {
    await this.pessoaService.excluirPessoa(this.state.codigo)
        .then(() => {
          this.toast.show({ severity: 'success', summary: 'Pessoas', detail: 'Pessoa excluída com sucesso', life: 4000 });
          //Resgatando consulta do storage
          const consultaFiltro = LocalStorageService.obterConsulta('_consulta_pessoa_filtro');
          const consultaFiltroJson = JSON.parse(consultaFiltro);
          this.pessoaService.consultarPessoas(consultaFiltroJson)
              .then(response => {
                this.setState({ clientes: response.data });
              }).catch(error => {
            this.setState(error.response.data)
            this.toast.show({ severity: 'error', summary: 'Pessoas', detail: `${this.state.detail}`, life: 4000 });
          })
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({ severity: 'error', summary: 'Pessoas', detail: `${this.state.detail}`, life: 4000 });
        })
  }

  async editarPessoa(pessoa) {
    this.props.history.push(`/consultarPessoas/${pessoa.codigo}`);
  }

  render() {
    return (
        <>
          <NavBar />
          <Toast ref={(el) => this.toast = el} />
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary card-outline">
                      <div className="card-header">
                        <h3 className="card-title">
                          <i className="fas fa-user-tie"></i>
                          <span> Pessoas</span>
                        </h3>
                      </div>

                      <div className="card-body">
                        <div className="flex-row">
                          <div>
                          <span className="input-group">
                            <div className="flex-row gap-1 space-right">
                              <ButtonSm type="button" className={strings.buttonPrimarySm}
                                        onClick={this.adicionarPessoa}
                                        aria-haspopup aria-controls="overlay_panel">
                              <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                            <ButtonSm id='FiltroAvancado' type="button" className={strings.buttonSecondarySm}
                                      data-toggle="modal"
                                      title="Filtro avançado"
                                      onClick={(e) => this.op.toggle(e)}
                                      aria-haspopup aria-controls="overlay_panel">
                              <i className="fas fa-filter"></i></ButtonSm>
                            </div>

                            <OverlayPanel ref={(el) => this.op = el} showCloseIcon id="overlay_panel"
                                          style={{ width: '750px' }}
                                          className="overlaypanel-demo"
                                          onShow={this.carregarCombos}>

                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label>Nome</label>
                                    <InputText id="nome"
                                               type="text"
                                               style={{ width: '100%' }}
                                               value={this.state.nome}
                                               onChange={e => this.setState({ nome: e.target.value })}
                                               className="p-inputtext-sm p-d-block p-mb-1" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label>CPF/CNPJ</label>
                                    <InputText id="cpfcnpj"
                                               type="text"
                                               style={{ width: '100%' }}
                                               value={this.state.cpfCnpj}
                                               onChange={e => this.setState({ cpfCnpj: e.target.value })}
                                               className="p-inputtext-sm p-d-block p-mb-1" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {this.state.estado === null ?
                                    <div className="col-sm-7">
                                      <div className="form-group">
                                        <label>Estado<span className="obrigatorioAsterisco"> *</span></label>
                                        <Dropdown value={this.state.estado}
                                                  options={this.state.estados}
                                                  optionLabel="estado"
                                                  filter showClear filterBy="estado"
                                                  style={{width: '100%'}}
                                                  onChange={this.onChangeEstado}
                                                  id="estado"
                                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                                      </div>
                                    </div>
                                    :
                                    <>
                                      <div className="col-sm-2">
                                        <div className="form-group">
                                          <label>Estado<span className="obrigatorioAsterisco"> *</span></label>
                                          <Dropdown value={this.state.estado}
                                                    options={this.state.estados}
                                                    optionLabel="estado"
                                                    filter showClear filterBy="estado"
                                                    style={{width: '100%'}}
                                                    onChange={this.onChangeEstado}
                                                    id="estado"
                                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>
                                      </div>
                                      <div className="col-sm-5">
                                        <div className="form-group">
                                          <label>Cidade<span className="obrigatorioAsterisco"> *</span></label>
                                          <Dropdown value={this.state.cidade}
                                                    options={this.state.cidades}
                                                    optionLabel="nome"
                                                    filter showClear filterBy="nome"
                                                    style={{width: '100%'}}
                                                    onChange={this.onChangeCidade}
                                                    id="cidade"
                                                    className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>
                                      </div>
                                    </>
                                }
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label>Grupo de pessoas</label>
                                    <Dropdown value={this.state.grupo}
                                              options={this.state.grupos} style={{width: '100%'}}
                                              optionLabel="nome"
                                              filter showClear filterBy="nome"
                                              onChange={this.onChangeGrupo}
                                              id="grupoConsulta"
                                              className="p-inputtext-sm p-d-block p-mb-1"/>
                                  </div>
                                </div>
                              </div>
                              <div className="direita">
                                <ButtonSm type="submit" className={strings.buttonSuccessSm}
                                          onClick={this.consultarPessoas}>
                                  <i className="fas fa-check"></i>Aplicar
                                </ButtonSm>
                              </div>

                            </OverlayPanel>
                            <form onSubmit={this.consultaRapida} className={"flex-row"}>
                            <input type="text" className="form-control"
                                   placeholder="Consulte uma pessoa"
                                   value={this.state.consultaNome}
                                   onChange={e => this.setState({consultaNome: e.target.value})}
                            />
                            <span className="input-group-prepend pointer">
                              <button type={"submit"} className="input-group-text">
                                <i className="fas fa-search"></i>
                              </button>
                            </span>
                            </form>
                          </span>
                          </div>
                        </div>

                        <div id='FiltroPorLetra' className="row gap-2 my-3" style={{paddingLeft: '0px'}}>
                          {this.alfabeto.map(letra => {
                            return <Button key={letra}
                                           onClick={() => this.onSelectLetra(letra)}
                                // 'letraMarcada' : 'letraNaoMarcada'
                                           className={`center-items ${this.state.letraCheck === letra ? strings.buttonDangerSm : strings.buttonPrimarySm}`}

                                           style={{
                                             fontSize: '14px',
                                             margin: "5px",
                                             cursor: 'pointer',
                                             width: "35px",
                                             height: "35px"
                                           }}>{letra}</Button>
                          })}
                        </div>


                        <ConfirmDialog visible={this.state.visible}
                                       onHide={() => this.setState({visible: false})}
                                       message="Are you sure you want to proceed?"
                                       header="Confirmation"
                                       icon="pi pi-exclamation-triangle"
                                       accept={() => this.excluirPessoa}
                                       reject={() => this.setState({ visible: false })} />

                        <Loading  open={this.state.openSpinner}/>

                        <DataTable id='TableResults' value={this.state.clientes} paginator responsiveLayout="scroll"
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10} emptyMessage=" "
                                   onRowClick={(rowData) => this.editarPessoa(rowData.data)}
                                   selectionMode="multiple">
                          <Column field="nome" header="Nome"></Column>
                          <Column field="tipoPessoaCalculada" header="Tipo de pessoa"></Column>
                          <Column field="perfil.nome" header="Perfil"></Column>
                          <Column field="cidade.nome" header="Cidade"></Column>
                          <Column field="email" header="Email"></Column>
                          <Column field="telefone1" header="Telefone"></Column>
                          <Column field="grupoCliente.nome" header="Grupo"></Column>
                          <Column field="statusCalculado" header="Status"></Column>
                          <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
    )
  }
}

Pessoas.contextType = AuthContext;
export default withRouter(Pessoas)
