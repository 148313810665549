import ApiService from '../apiservice'

export default class ProcessoAutomaticoService extends ApiService {

  constructor() {
    super('/processoAutomatico');
  }

  async cadastrar(processo) {
    return await this.post('',processo);
  }

  async getSistemas() {
    return await this.get('/sistemaRobot');
  }
  async getUsuarioNoCadastroDeProcesso(empresa) {
    return await this.get(`/usuarios/${empresa}`);
  }
}
