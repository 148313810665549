import ApiService from '../apiservice'

class EmpresaPlanosService extends ApiService {

  constructor() {
    super('/empresa/planos')
  }

  cadastrarEmpresaPlano(codigoEmpresa, tipoPlano) {
    return this.post(`/${codigoEmpresa}`, tipoPlano);
  }
  getEmpresaPlanoExpirou(codigoEmpresa) {
    return this.get(`/expirou/${codigoEmpresa}`);
  }
  getTiposPlanos() {
    return this.get('/tipos');
  }
}


export default EmpresaPlanosService;
