import React from 'react'
import {withRouter} from 'react-router-dom'

import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {Toast} from 'primereact/toast';
import {InputMask} from 'primereact/inputmask';
import {Button} from 'primereact/button';
import {Message} from 'primereact/message';

import EstadoService from '../../app/service/EstadoService'
import CidadeService from '../../app/service/CidadeService'
import EmpresaService from '../../app/service/EmpresaService'
import CnpjService from '../../app/service/CnpjService'
import {AuthContext} from '../../main/ProvedorAutenticacao'
import ReCAPTCHA from "react-google-recaptcha";
// import * as Yup from 'yup';
import './usuarios.css'
import {IsPasswordEquals, IsValidPassword} from "../../utils/Validator";
import {Password} from "primereact/password";
import {strings} from "../../utils/strings";
import Logo from '../../img/etrium-logo1.svg';

class Cadastrar extends React.Component {
    
    constructor(props) {
        super(props);

        this.estadoService = new EstadoService();
        this.cidadeService = new CidadeService();
        this.empresaService = new EmpresaService();
        this.cnpjService = new CnpjService();
        this.onEstadoChange = this.onEstadoChange.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);
        this.buscarDadosCnpj = this.buscarDadosCnpj.bind(this);

        this.state = {
            //Cadastro de Usuário
            nome: '',
            email: '',
            cnpj: '',
            razaoSocial: '',
            telefone1: '',
            estados: [],
            cidades: [],
            perfil: {codigo: '1'},
            selectEstado: null,
            selectCidade: null,
            // perfis: [],
            senha: '',
            repitaSenha: '',
            captcha: '',
            isValidPassword: false,
            isValidRepeatPassword: false,
            step: false
        };
    }


    onStateChange = (e) => {
        const {name, value} = e.target;

        this.setState({[name]: value})

        if (name === 'cnpj' && value.length === 18) {
            this.buscarDadosCnpj(value);
        }
    }

    async buscarDadosCnpj(cnpj) {
        try {
            const cnpjLimpo = cnpj.replace(/[^\d]/g, '');
        
            const response = await this.cnpjService.buscarCnpj(cnpjLimpo);
            
            if (response && response.data) {
                this.setState({ razaoSocial: response.data.razao_social });

                if (response.data.uf) {
                    this.selecionarEstadoPorUf(response.data.uf);

                    if (response.data.municipio) {
                        this.selecionarCidadePorNome(response.data.municipio);
                    }
                }
            }
        } catch (error) {
            console.error("Erro ao buscar dados do CNPJ", error);
        }
    }

    selecionarEstadoPorUf = async (uf) => {
        const estadoSelecionado = this.state.estados.find(estado => estado.estado === uf);
        
        if (estadoSelecionado) {
            await this.setState({ selectEstado: estadoSelecionado });
            this.listarCidades();
        } else {
            console.error(`Estado com UF ${uf} não encontrado.`);
        }
    }
    

    selecionarCidadePorNome = async (cidade) => {
        // Função para remover acentos e normalizar as strings
        const normalizeString = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        };
    

        const cidadeNormalizada = normalizeString(cidade);
        
        const cidadeSelecionada = this.state.cidades.find(cidadeObj => {
            const nomeCidadeNormalizada = normalizeString(cidadeObj.nome);
            return nomeCidadeNormalizada === cidadeNormalizada;
        });
    
        if (cidadeSelecionada) {
            this.setState({ selectCidade: cidadeSelecionada });
        }
    }

    
    listarEstados = async () => {
        await this.estadoService.listarEstados()
            .then(response => {
                this.setState({estados: response.data});
            })
    }

    cadastrarEmpresa = async () => {
        let telefone = this.state.telefone1.replace(/\D/g, '');
        let empresa = {
            nomeUsuario: this.state.nome,
            email: this.state.email,
            nomeEmpresa: this.state.razaoSocial,
            telefone: telefone,
            cnpj: this.state.cnpj,
            cidade: this.state.selectCidade,
            senha: this.state.senha,
            captchaToken: this.state.captcha
        }
        this.empresaService.cadastrarNovaEmpresa(empresa)
            .then(async () => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Cadastro de usuários',
                    detail: 'Cadastro Realizado com Sucesso.',
                    life: 4000
                });
                this.limparCampos();
                setTimeout(() => {
                    this.props.history.push('/welcome')
                }, 3000)
            }).catch((error) => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Cadastrar empresa',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    limparCampos = () => {
        this.setState({
            nome: '',
            email: '',
            cpf: '',
            telefone1: '',
            selectEstado: null,
            cidade: null,
            selectCidade: null,
            senha: '',
            repitaSenha: '',
            cnpj: '',
            razaoSocial: '',
            isValidPassword: false,
            isValidRepeatPassword: false,
            mostrarInstrucoes: false,
            captcha: ''
        })
    }

    cadastrarEmpresaVerificaCampos = async (e) => {
        e.preventDefault();

        if (this.isNullOrBlank(this.state.cnpj)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o CNPJ',
                life: 4000
            });
            return false;
        }
        if (this.isNullOrBlank(this.state.razaoSocial)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a razão social',
                life: 4000
            });
            return false;
        }

        if (this.isNullOrBlank(this.state.selectEstado)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o estado',
                life: 4000
            });
            return false;
        }
        if (this.state.selectCidade == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }

        if(!this.state.step){
            this.setState({
                step: true
            });
            return
        }


        if (this.isNullOrBlank(this.state.nome)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o nome do usuário',
                life: 4000
            });
            return false;
        }

        if (this.isNullOrBlank(this.state.email)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o e-mail do usuário',
                life: 4000
            });
            return false;
        }


        if (this.isNullOrBlank(this.state.selectCidade)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }

        if (this.state.perfil == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o perfil do usuário',
                life: 4000
            });
            return false;
        }
        if (this.state.telefone1 === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe um telefone',
                life: 4000
            });
            return false;
        }

        if (this.isNullOrBlank(this.state.senha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a senha',
                life: 4000
            });
            return false;
        }
        if (this.isNullOrBlank(this.state.repitaSenha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe confirmar senha',
                life: 4000
            });
            return false;
        }
        if (this.state.repitaSenha !== this.state.senha) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'As senhas não coincidem',
                life: 4000
            });
            return false;
        }

        if (!IsValidPassword(this.state.senha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Senha inválida',
                life: 4000
            });
            return false;
        }


        if (this.isNullOrBlank(this.state.captcha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Verificação não foi resolvida',
                life: 4000
            });
            return false;
        }

        //cadastra empresa logo apos cadastra o usuario
        await this.cadastrarEmpresa()

    }

    voltar = () => {
        if(this.state.step){
            this.setState({
                step: false
            });
            return
        }
        this.props.history.push('/login')
    }

    async onEstadoChange(e) {
        await this.setState({selectEstado: e.value});
        this.listarCidades();
    }

    onCidadeChange(e) {
        this.setState({selectCidade: e.value})
    }

    listarCidades = async () => {
        if (this.state.selectEstado) {
            await this.cidadeService.listarCidades(this.state.selectEstado.codigo)
                .then(response => {
                    this.setState({cidades: response.data})
                }).catch(error => {
                    this.setState(error.response.data)
                    this.toast.show({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: `${this.state.detail}`,
                        life: 4000
                    });
                })
        }
    }

    isNullOrBlank = (item) => {
        if (item == null) {
            return true
        }
        return item === '';
    }

    componentDidMount() {
        this.listarEstados();
    }

//verificando  se senha está de acorodo com o sistema
    handlePasswordInput = e => {
        // Set the value of the password to the current value of the input

        this.setState({senha: e.target.value});
        // Check that the password is at least 8 characters long
        if (IsValidPassword(e.target.value)) {
            this.setState({isValidPassword: true})
        } else {
            this.setState({isValidPassword: false})
        }
    }
    handleRepeatPasswordInput = e => {
        // Set the value of the password to the current value of the input

        this.setState({repitaSenha: e.target.value});
        // Check that the password is at least 8 characters long
        if (IsPasswordEquals(e.target.value, this.state.senha)) {
            this.setState({isValidRepeatPassword: true})
        } else {
            this.setState({isValidRepeatPassword: false})
        }
    }

    onPasswordFocusEnd = () => {
        const {senha} = this.state
        if (!IsValidPassword(senha) && senha.length > 0) {
            ///add instruçoes para criar senha
            this.setState({mostrarInstrucoes: true})
        } else {
            this.setState({mostrarInstrucoes: false})

        }
    }
    onPasswordRepeatFocusEnd = () => {
        const {senha, repitaSenha} = this.state
        if (!IsPasswordEquals(senha, repitaSenha) && repitaSenha.length > 0) {

        }
    }

    stringsIntrucoes = () => {
        return <div className={'flex-column'}>
            <span>Senha inválida! Insira uma senha seguindo as intruções abaixo</span>
            <span>uma letra maiúscula e uma minúscula (A-Z, a-z) </span>
            <span>um número (0-9)</span>
            <span>e  no minimo 8 (oito) caracteres</span>
        </div>
    }

    alerts = (msg) => {
        return <div className={'mt-1'}><Message severity="warn" text={msg}/></div>
    }
    alertsucess = (msg) => {
        return <div className={'mt-1'}><Message severity="success" text={msg}/></div>
    }

    formStepOne = () => {
    return (
        <form onSubmit={this.cadastrarEmpresaVerificaCampos}>
            <div className="group">
                <label>CNPJ<span
                    className="obrigatorioAsterisco"> *</span></label>
                <InputMask mask="99.999.999/9999-99"
                           id="cpnj"
                           name='cnpj'
                           type="text"
                           style={{width: '100%'}}
                           value={this.state.cnpj}
                           onChange={this.onStateChange}
                           className="p-inputtext-sm p-d-block p-mb-1"/>
            </div>
            <div className="group">
                <label>Razão social<span
                    className="obrigatorioAsterisco"> *</span></label>
                <InputText id="razaoSocial"
                           name="razaoSocial"
                           type="text"
                           style={{width: '100%'}}
                           autoComplete="off"
                           value={this.state.razaoSocial}
                           onChange={this.onStateChange}
                           className="p-inputtext-sm p-d-block p-mb-1"/>


            </div>
            <div className="form-group" style={{flex: 1}}>
                <label>Estado<span
                    className="obrigatorioAsterisco"> *</span></label>
                <Dropdown value={this.state.selectEstado}
                          options={this.state.estados}
                          style={{width: '100%'}}
                          optionLabel="estado"
                          filter showClear filterBy="estado"
                          onChange={this.onEstadoChange}
                          id="estado"
                          className="p-inputtext-sm p-d-block p-mb-1"/>
            </div>
            <div className="form-group"
                 style={{flex: 1}}>
                <label>Cidade<span
                    className="obrigatorioAsterisco"> *</span></label>
                <Dropdown
                    value={this.state.selectCidade}
                    options={this.state.cidades}
                    style={{width: '100%'}}
                    optionLabel="nome"
                    emptyMessage="Nenhuma cidade para este estado"
                    filter showClear filterBy="nome"
                    onChange={this.onCidadeChange}
                    id="cidade"
                    className="p-inputtext-sm p-d-block p-mb-1"/>
            </div>
            <div className='flex-column gap-1rem'>

                <Button
                        type={'submit'}
                        className={strings.buttonPrimaryStyle  + " btn-login100"}
                        onClick={this.cadastrarEmpresaVerificaCampos}>
                   Avançar
                </Button>

                <div className="divider-login"/>
                <span>Você ja possui uma conta? <a className={"link-etrium-login"} href={"#/login"}>Fazer Login</a></span>
            </div>
        </form>
    )
    }

    formStepTwo = () => {
        return (
            <form onSubmit={this.cadastrarEmpresaVerificaCampos}>

                <div className="form-group">
                <label>Nome<span
                        className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="nome"
                               autoFocus={true}
                               type="text"
                               style={{width: '100%'}}
                               value={this.state.nome}
                               onChange={e => this.setState({nome: e.target.value})}
                               className="p-inputtext-sm p-d-block p-mb-1"/>
                </div>

                <div className="group">
                    <label>E-mail<span
                        className="obrigatorioAsterisco"> *</span></label>
                    <InputText id="email"
                               type="text"
                               style={{width: '100%'}}
                               autoComplete="off"
                               value={this.state.email}
                               onChange={e => this.setState({email: e.target.value})}
                               className="p-inputtext-sm p-d-block p-mb-1"/>

                </div>

                <div className="group" style={{width: '100%'}}>
                    <label>Telefone<span
                        className="obrigatorioAsterisco"> *</span></label>
                    <InputMask mask="(99)9 9999-9999"
                               id="telefone1"
                               name="telefone1"
                               type="text"
                               style={{width: '100%'}}
                               value={this.state.telefone1}
                               onChange={this.onStateChange}
                               className="p-inputtext-sm p-d-block p-mb-1"/>


                </div>


                <div className="group">
                    <label>Senha<span
                        className="obrigatorioAsterisco"> *</span></label>
                    <Password
                        inputStyle={{width: '100%', flex: 1}}
                        autoComplete="nope"
                        id="senha"
                        name="senha"
                        toggleMask
                        feedback={false}
                        style={{
                            width: "100%",
                            borderColor: this.state.isValidPassword ? 'green' : ''
                        }}
                        value={this.state.senha}
                        onBlur={this.onPasswordFocusEnd} //quando elemento sai de foco
                        onChange={this.handlePasswordInput}
                        className="p-inputtext-sm p-d-block p-mb-1"/>
                    {this.state.isValidPassword &&
                        this.alertsucess("Senha válida!")
                    }
                    {/*{this.state.senha.length > 0 && !this.state.isValidPassword &&*/}
                    {/*    this.alerts(this.stringsIntrucoes)*/}
                    {/*}*/}
                </div>

                <div className="form-group">
                    <label>Repita a senha<span
                        className="obrigatorioAsterisco"> *</span></label>
                    <Password
                        inputStyle={{width: '100%', flex: 1}}
                        autoComplete="nope"
                        id="repitaSenha"
                        name="repitaSenha"
                        toggleMask
                        feedback={false}
                        style={{
                            width: '100%',
                            borderColor: this.state.isValidPassword ? 'green' : ''
                        }}
                        value={this.state.repitaSenha}
                        onBlur={this.onPasswordRepeatFocusEnd} //quando elemento sai de foco
                        onChange={this.handleRepeatPasswordInput}
                        className="p-inputtext-sm p-d-block p-mb-1"/>
                    {this.state.repitaSenha.length > 0 && this.state.isValidRepeatPassword &&
                        this.alertsucess("Senhas iguais!")
                    }{this.state.repitaSenha.length > 0 && !this.state.isValidRepeatPassword &&
                    this.alerts("As senhas são diferentes!")
                }


                </div>
                <div className={"flex-column form-group font-size-13"}>
                    <span>A senha precisa ter:</span>
                    <span>Uma letra maiúscula e uma minúscula (A-Z, a-z)</span>
                    <span>um número (0-9)</span>
                    <span>e  no minimo 8 (oito) caracteres</span>
                </div>
                <div className='form-group'>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        name='captcha'
                        onChange={(value) => this.setState({captcha: value})}/>
                </div>
                <div className='row-etrium gap-1'>
                    <Button
                            type={'submit'}
                            className={strings.buttonPrimaryStyle  + " btn-login100"}
                            onClick={this.cadastrarEmpresaVerificaCampos}>
                        Cadastrar
                    </Button>
                    <Button
                            type={'button'}
                            className={strings.buttonSecondaryStyle  + " btn-login100"}
                            onClick={this.voltar}>
                        Voltar
                    </Button>
                </div>

            </form>
        )
    }



    render() {
        const {step} = this.state
        const stringUsuarioEmpresa = step ? "Agora me fale mais sobre você" : "Me fale mais sobre sua empresa"
        return (
            <>
                <Toast ref={(el) => this.toast = el}/>
                <div className="limiter">
                    <div className="container-login100">
                        <div className={"flex flex-row w-full"}>
                            <div className={"full-screen-container"}>
                                {/*<img className={"full-screen-image"} src={imagejuridico}*/}
                                {/*     alt='imagem logo justica'/>*/}
                            </div>
                            <div className="container-logo-form">
                                <div className="logo-container">
                                    <img className="logo" src={Logo} alt='Logo Etrium'></img>
                                </div>
                                <div className={"form-s"}>
                                    <div>
                                        <div>
                                            {/*<h1 className="txt1">Bem vindo!</h1>*/}
                                            {/*<p className="txt2">Acesse sua conta</p>*/}
                                            <h1 className="text-login-h1">Cadastre-se</h1>
                                            <p className="txt2">{stringUsuarioEmpresa}</p>
                                        </div>
                                        {!step ? this.formStepOne() : this.formStepTwo()}
                                        {/*fim inputs*/}

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </>
        );
    }
}

Cadastrar.contextType = AuthContext;
export default withRouter(Cadastrar);
