import React, {useContext, useEffect, useState} from "react";
import {CardPlano} from "../../componentes/Empresa/CardPlano";
import EmpresaPlanosService from "../../app/service/EmpresaPlanosService";
import {EmpresaConsts} from "../../Consts/EmpresaConsts";
import PaymentPlanosService from "../../app/service/PaymentPlanosService";
import LocalStorageService from "../../app/service/localStorageService";
import {USUARIO_LOGADO} from "../../app/service/AuthService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import Navbar from "../../componentes/Navbar";
import AlterarPlanos from "./AlterarPlano";


const PlanosEmpresa = () => {

    const [planos, setPlanos] = useState([])
    const {usuarioAutenticado} = useContext(AuthContext)
    const user = usuarioAutenticado
    const empresa = user.empresa;
    const planoAtual = empresa.plano.tipoPlano.codigo;
    const empresaPlanosService = new EmpresaPlanosService()

    function findTipoPlanos() {
        empresaPlanosService.getTiposPlanos()
            .then((res) => {
                let data = res.data
                setPlanos(data)
            })
    }

    const planoTry = planos.find(tipo => tipo.codigo === EmpresaConsts.TRY)
    const planoStarted = planos.find(tipo => tipo.codigo === EmpresaConsts.STARTED)
    const planoStartedYear = planos.find(tipo => tipo.codigo === EmpresaConsts.STARTED_YEAR)
    const planoStandart = planos.find(tipo => tipo.codigo === EmpresaConsts.STANDART)
    const planoStandartYear = planos.find(tipo => tipo.codigo === EmpresaConsts.STANDART_YEAR)
    const handleAssinar = async (plano, intervalo) => {
        const cdEmpresa = user.empresa.codigo;
        const tipoPlano = plano.codigo;
        // Convertendo o valor do plano de reais para centavos
        const valorEmCentavos = plano.valor * 100;

        const pagamentoRequest = {
            SoftDescriptor: "Recorrencia",
            Cart: {
                Items: [
                    {
                        Name: plano.nome,
                        Description: plano.descricao || "Assinatura",
                        UnitPrice: valorEmCentavos,
                        Quantity: 1,
                        Type: "Service",
                    },
                ],
            },
            Shipping: {
                Type: "WithoutShipping",
            },
            Payment: {
                BoletoDiscount: 0,
                DebitDiscount: 0,
                RecurrentPayment: {
                    Interval: intervalo,
                    EndDate: "",
                },
            },
        };

        try {
            const response = await new PaymentPlanosService().criarPagamento(
                pagamentoRequest,
                cdEmpresa,
                tipoPlano
            );
            const checkoutUrl = response.data.checkoutUrl;
            if (checkoutUrl) {
                window.open(checkoutUrl, "_blank");
            } else {
                console.error("URL de checkout não encontrado na resposta da API.");
            }
        } catch (error) {
            console.error("Erro ao criar pagamento:", error);
        }
    };

    const handleEncerrar = async () => {
        const cdEmpresa = user.empresa.codigo;
        try {
            const response = await new PaymentPlanosService().desativarRecurrence(cdEmpresa);
        } catch (error) {
            console.error("Erro ao encerrar o plano:", error);
        }
    };
    useEffect(findTipoPlanos, [])
    const Cards = () => {
        return (
            <div className='card card-primary card-outline gap-1'>
                <div className='card-header'>
                    <h3 className="card-title row-etrium gap-1 align-center">
                        <i className="fa fa-solid fa-cart-plus"></i>
                        <span>Contratar um plano</span>
                    </h3>
                </div>
                <div className={"card-body"}>
                   <AlterarPlanos/>
                </div>
            </div>
        )
    }
    if(empresa.status === 3){
        return (
            <div className="">
                <div className="container-fluid">
                    <section className="content-header">
                        <Cards/>
                    </section>
                </div>
            </div>
        )
    }
    return (
        <>
             <Navbar/>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <section className="content-header">
                            <Cards/>
                        </section>
                    </div>
                </div>
        </>

    )
}
export default PlanosEmpresa
