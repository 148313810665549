import React, {useEffect, useState} from 'react';
import EmpresaPlanosService from "../../app/service/EmpresaPlanosService";
import {EmpresaConsts} from "../../Consts/EmpresaConsts";
import {formatarMoeda} from "../../utils/formatar-moeda-tela";
import '../../componentes/styles/navbar.css';
import logo from './img/etrium-logomenu.png'

const Welcome = () => {

    const [planos, setPlanos] = useState([])

    const empresaPlanosService = new EmpresaPlanosService()

    function findTipoPlanos() {
        empresaPlanosService.getTiposPlanos()
            .then((res) => {
                let data = res.data
                setPlanos(data)
            })
    }

    const planoTry = planos.find(tipo => tipo.codigo === EmpresaConsts.TRY)
    const planoStarted = planos.find(tipo => tipo.codigo === EmpresaConsts.STARTED)
    const planoStartedYear = planos.find(tipo => tipo.codigo === EmpresaConsts.STARTED_YEAR)
    const planoStandart = planos.find(tipo => tipo.codigo === EmpresaConsts.STANDART)
    const planoStandartYear = planos.find(tipo => tipo.codigo === EmpresaConsts.STANDART_YEAR)
    const planoEnterprise = planos.find(tipo => tipo.codigo === EmpresaConsts.ENTERPRISE)

    useEffect(findTipoPlanos, [])

    const CardPlano = ({planoMensal, planoAnual, selected}) => {

        if (planoMensal && planoMensal.codigo === EmpresaConsts.ENTERPRISE) {
            return (
                <div className="card-deck my-2">
                    <div className="card m-l-0">
                        <div className="flex-row card-header justify-content-center text-bold text-lg">
                            Empresarial
                        </div>
                        <div className="flex flex-col card-body flex-column gap-5 pb-0">
                            <div id={'head'} className={"flex-column"} style={{height: "50px"}}>
                                <h5 className="card-title justify-content-center align-items-center flex-row">A
                                    combinar</h5>
                            </div>
                            <div id={"body"} className="flex-column gap-5 mb-5">
                                <span className="card-text text-black"><i className="pi pi-user text-blue"></i> vários usuários</span>
                                <span className="card-text text-black"><i
                                    className="fas fa-balance-scale text-blue"></i> vários processos</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Quadro de atividades</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Gestão processual</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Controle financeiro</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Geração de relatórios</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Robôs de pesquisa de processos</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Funcionalidades de parecer</span>
                            </div>
                            <div className="flex flex-col justify-content-start">
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (planoMensal) {
            return (
                <div className="card-deck my-2">
                    <div className="card m-l-0"
                         style={{borderColor: selected ? '#0072C7' : 'white', borderWidth: selected ? '2px' : "0px"}}>
                        <div className="flex-row card-header justify-content-center text-bold text-lg">
                            {planoMensal.nome}
                        </div>
                        <div className="card-body flex-column gap-5 pb-0">
                            <div id={'head'} className="flex-column" style={{height: "50px"}}>
                                {planoMensal.codigo === 1
                                    ?
                                    <h5 className="card-title justify-content-center align-items-center flex-row">Gratuita
                                        por 7 dias</h5>
                                    : <>
                                        <h5 className="card-title justify-content-center align-items-center flex-row">{formatarMoeda(planoMensal.valor)} /
                                            mês</h5>
                                        {planoAnual &&
                                            <h5 className="card-title justify-content-center align-items-center flex-row">{formatarMoeda(planoAnual.valor)} /
                                                ano</h5>}
                                    </>
                                }
                            </div>
                            <div id={"body"} className="flex-column gap-5 mb-5">
                                <span className="card-text text-black"><i
                                    className="pi pi-user text-blue"></i> {planoMensal.quantidadeUsuarios} usuário(s)</span>
                                <span className="card-text text-black"><i
                                    className="fas fa-balance-scale text-blue"></i> {planoMensal.quantidadeProcessos} processos</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Quadro de atividades</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Gestão processual</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Controle financeiro</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Geração de relatórios</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Robôs de pesquisa de processos</span>
                                <span className="card-text text-black"><i className="pi pi-check text-blue"></i> Funcionalidades de parecer</span>
                                {/*<span className="card-text text-black">{planoMensal.codigo >= 4 || planoMensal.codigo === 1 ? <i className="pi pi-check text-blue"></i> : <i className="pi pi-times text-red"></i>} Controle financeiro</span>*/}
                                {/*<span className="card-text text-black">{planoMensal.codigo >= 4 || planoMensal.codigo === 1 ? <i className="pi pi-check text-blue"></i> : <i className="pi pi-times text-red"></i>} Geração de relatórios</span>*/}
                                {/*<span className="card-text text-black">{planoMensal.codigo >= 6 || planoMensal.codigo === 1 ? <i className="pi pi-check text-blue"></i> : <i className="pi pi-times text-red"></i>} Robôs de pesquisa de processos</span>*/}
                                {/*<span className="card-text text-black">{planoMensal.codigo >= 6 || planoMensal.codigo === 1 ? <i className="pi pi-check text-blue"></i> : <i className="pi pi-times text-red"></i>} Funcionalidades de parecer</span>*/}
                            </div>

                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };


    const NavbarWelcome = () => {
        return (
            <div
                className="flex-1 mb-3">
                <div className="navbar navbar-white navbar-light border-blue flex-1">
                    <div className="navbar-logo">
                        <a href={"/#/login"} className="text-primary no-effect">
                            <img src={logo} alt="AdminLTE Logo" className="img-logo-home" style={{marginLeft: '30px'}}/>
                            <span className="navbar-title">Etrium</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <NavbarWelcome/>
            <div className="container-fluid">
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <div className="card text-center w-100">
                            <div className={"card-body flex-col"}>
                                <h1 className={"card-title text-xl"}>Bem-vindo!</h1>
                                <span className={"card-text"}>
                                Obrigado por se cadastrar no Etrium.
                            </span>
                                <span className="card-text text-primary font-weight-bold">
                                Por favor, verifique seu e-mail para acessar o sistema.
                            </span>

                                <div className={"flex-col gap-0 justify-content-center mt-1"}>
                                <span className={"card-text"}>Você está começando com nosso plano de Avaliação Gratuita,
                                    válido por 7 dias. Aproveite
                                    para explorar todas as funcionalidades!</span>
                                    <span>Após o período de avaliação, você poderá escolher um dos planos abaixo para continuar
                                    usando o sistema.</span>
                                    <div className={"flex-row gap-4 justify-content-center mt-1"}>
                                        {/*<CardPlano planoMensal={planoTry} selected={true}/>*/}
                                        <CardPlano planoMensal={planoStarted} planoAnual={planoStartedYear}/>
                                        <CardPlano planoMensal={planoStandart} planoAnual={planoStandartYear}/>
                                        <CardPlano planoMensal={planoEnterprise}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Welcome;
